export default {
  methods: {
    validate () {
      let valid = true

      /**
       * Loop through $refs and execute their validate() functions.
       * Break if any is invalid.
       */
      for (const key in this.$refs) {
        const refIsValid = this.$refs[key].validate()
        if (!refIsValid) {
          valid = false
          break
        }
      }

      return valid
    },
    checkDirty () {
      let dirty = false

      /**
       * Loop through $refs and check if any fields are 'dirty'.
       * Break if any is dirty.
       */
      for (const key in this.$refs) {
        if (!this.$refs[key] || !this.$refs[key].$v) continue

        const anyDirty = this.$refs[key].$v.$anyDirty
        if (anyDirty) {
          dirty = true
          break
        }
      }

      return dirty
    },
    resetValidations () {
      /**
       * Loop through $refs and reset any vuelidate validations.
       */
      for (const key in this.$refs) {
        if (!this.$refs[key] || !this.$refs[key].$v) continue
        this.$refs[key].$v.$reset()
      }
    }
  }
}
