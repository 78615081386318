<template>
  <v-row no-gutters justify="center">
    <v-col cols="12" md="8" lg="6">
      <v-alert v-if="info" text dense type="info">{{ info }}</v-alert>

      <v-alert v-if="success" text dense type="success">{{ success }}</v-alert>

      <v-alert v-if="error" text dense type="error">{{ error }}</v-alert>

      <v-form v-if="editing">
        <!-- Profile -->
        <template>
          <pibot-form-block-profile ref="profileForm" />
          <v-divider class="my-3" />
        </template>

        <!-- Password reset -->
        <template>
          <pibot-settings-password-reset-user v-if="editingMe" @success="setSuccess" @error="setError" @loading="setLoadingState" />
          <pibot-settings-password-reset-admin v-else-if="$grantAccess($me, 'updateAny', 'profile')" @error="setError" @success="setSuccess" @loading="setLoadingState" />
          <v-divider class="my-3" />
        </template>

        <!-- Access Control -->
        <template v-if="editing._id !== $me._id">
          <pibot-form-block-ac ref="acForm" />
          <v-divider class="my-3" />
        </template>

        <!-- Form actions -->
        <v-row justify="space-between">
          <v-col cols="auto">
            <v-btn
              color="success"
              depressed
              small
              :loading="loading"
              @click="validateAndUpdate"
            >
              Update profile
            </v-btn>
          </v-col>

          <!-- <v-col cols="auto">
            <v-btn
              color="success"
              depressed
              small
              :loading="loading"
              disabled
            >
              Cancel
            </v-btn>
          </v-col> -->
        </v-row>

        <!-- Danger Zone -->
        <!--
          In here we use v-show instead of v-if.
          This is because 'dangerousOptions' gets updated before @loading gets executed,
          which causes @loading to not get executed at all if we use v-if
        -->
        <template v-if="dangerousOptions.length">
          <div v-show="dangerousOptions.includes('delete-profile')">
            <v-divider class="my-3" />
            <pibot-form-block-delete-profile
              @success="setSuccess"
              @error="setError"
              @loading="setLoadingState"
            />
          </div>

          <div v-show="dangerousOptions.includes('create-profile')">
            <v-divider class="my-3" />
            <pibot-form-block-activate-profile
              @success="setSuccess"
              @error="setError"
              @loading="setLoadingState"
            />
          </div>
        </template>
      </v-form>

      <v-alert v-else text dense type="error">
        User not found
      </v-alert>

      <pibot-confirm-exit-dialog
        :value="dialog"
        :to="to"
        @decline="dialog = false"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import refValidationsMixin from '@/utils/mixins/forms/refValidations.mixin'

export default {
  name: 'pibot-settings-user',
  mixins: [refValidationsMixin],
  components: {
    'pibot-form-block-profile': () => import('@/components/form-blocks/profile/Profile'),
    'pibot-settings-password-reset-user': () => import('@/components/form-blocks/profile/PasswordResetUser'),
    'pibot-settings-password-reset-admin': () => import('@/components/form-blocks/profile/PasswordResetAdmin'),
    'pibot-form-block-ac': () => import('@/components/form-blocks/profile/AccessControl'),
    'pibot-form-block-delete-profile': () => import('@/components/form-blocks/profile/DeleteProfile'),
    'pibot-form-block-activate-profile': () => import('@/components/form-blocks/profile/ActivateProfile'),
    'pibot-confirm-exit-dialog': () => import('@/components/dialogs/ConfirmExit')
  },
  data: () => ({
    success: false,
    error: false,
    dialog: false,
    to: null
  }),
  computed: {
    ...mapState({
      users: state => state.settings.users,
      editing: state => state.settings.editing,
      loading: state => state.settings.loading
    }),
    editingMe () {
      return this.editing && this.$me._id === this.editing._id
    },
    info () {
      return this.editing && !this.editingMe ? 'You are editing someone else\'s profile' : false
    },
    dangerousOptions () {
      const options = []

      if (
        this.editing._id !== this.$me._id &&
        this.editing.status &&
        this.$grantAccess(this.$me, 'deleteAny', 'profile')
      ) options.push('delete-profile')

      if (
        this.editing._id !== this.$me._id &&
        !this.editing.status &&
        this.$grantAccess(this.$me, 'createAny', 'profile')
      ) options.push('create-profile')

      return options
    }
  },
  methods: {
    ...mapMutations({
      setEditingUser: 'settings/SET_EDITING_USER',
      setLoadingState: 'settings/SET_LOADING_STATE'
    }),
    ...mapActions({
      fetchUsers: 'settings/socket_fetchUsers',
      tryProfileUpdate: 'settings/socket_tryProfileUpdate'
    }),
    init () {
      if (this.loading) return // Check if already loading so we don't execute this twice
      this.setLoadingState(true)

      const isCreated = this.$route.path.includes('/created/')

      // Reset form upon creation
      if (this.users) {
        this.reset()
        if (isCreated) this.handleCreatedMessage()
      } else if (this.$grantAccess(this.$me, 'readAny', 'profile')) {
        this.fetchUsers().then(() => {
          this.reset()
          if (isCreated) this.handleCreatedMessage()
        })
      } else {
        this.reset()
        if (isCreated) this.handleCreatedMessage()
      }
    },
    initEditingUser () {
      /**
       * Get the user which we are currently editing.
       *
       * Find the user by the the route param '_id' if set
       * or use the current user.
       */
      const editing = this.$route.params._id
        ? this.users.find(u => u._id === this.$route.params._id)
        : this.$me

      if (editing) this.setEditingUser(JSON.parse(JSON.stringify(editing)))
      else this.setEditingUser(editing)
    },
    clear () {
      this.initEditingUser()
      this.resetValidations()
    },
    reset () {
      this.setSuccess(false)
      this.setError(false)
      this.setLoadingState(false)
      this.clear()
    },
    validateAndUpdate () {
      const isValid = this.validate()
      if (!isValid) return

      this.setLoadingState(true)

      this.tryProfileUpdate(this.editing)
        .then(() => {
          this.setSuccess('Profile successfully updated')
          this.setError(false)
          this.clear()
        })
        .catch(error => {
          this.setSuccess(false)
          this.setError(error.message)
        })
        .finally(() => {
          this.setLoadingState(false)
        })
    },
    setError (error) {
      this.error = error
    },
    setSuccess (message) {
      this.success = message
    },
    handleCreatedMessage () {
      this.setSuccess('Profile successfully created')
    }
  },
  beforeRouteEnter (to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    next(vm => vm.init)
  },
  beforeRouteLeave (to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.

    if (!this.loading && !this.dialog && this.checkDirty()) {
      this.dialog = true
      this.to = to
    } else {
      this.dialog = false
      next()
      // Always remove the editing user when exiting this route
      this.setEditingUser(null)
    }
  },
  created () {
    this.init()
  }
}
</script>
